<template>
    <div class="pagebox">

        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 新闻内容 -->
        <div class="noticediv">
            <div class="noticecon">
                <div class="tit">{{newsdata.NewsTitle}}</div>
                <div class="time">{{newsdata.ReleaseDate.substring(0,16)}}</div>
                <div class="txt" v-html="newsdata.RichTextContent"></div>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>

    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import { getStore,setStore } from "@/utils/utils";
import API from "@/api/config";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            newsdata:{
                NewsTitle:'',
                ReleaseDate:'',
                RichTextContent:''
            }
        }
    },
    mounted(){
        API.Web.GetNewsInfoDetail({id:this.$route.query.id}).then(res => {
            if(res.Code===0){
                this.newsdata = res.Data;
                this.newsdata.RichTextContent.replace(/\<video/gi, "<video width='100%'");
                //增加点击量
                let cdata = JSON.parse(getStore("NewsAccessData"));
                let fdata = JSON.parse(getStore("NewsAccessData"));
                if(fdata==null||fdata==''){
                    this.AddNewsInfoCount(this.$route.query.id,cdata);
                }
                else{
                    // 清理掉已过期的
                    fdata.forEach(e => {
                        let num = ((new Date().getTime()-new Date(e.Time).getTime()) / 1000) / 60;
                        if(num>=5){
                            cdata = cdata.filter(f=>f.Id==e.Id);
                        }
                    });
                    // 判断当前Id是否在已清理后（未过期）的集合中
                    let item = cdata.find(f=>f.Id==this.$route.query.id);
                    if(item==null||item==undefined){
                        //不在清理后（未过期）的集合中，增加点击量
                        this.AddNewsInfoCount(this.$route.query.id,cdata);
                    }
                }
            }
        });
    },
    methods:{
        //增加点击量
        AddNewsInfoCount(id,cdata){
            if(cdata==null||cdata==''){
                cdata = [{"Id":id,"Time":new Date()}];
            }
            else{
                cdata.push({"Id":id,"Time":new Date()});
            }
            setStore("NewsAccessData",cdata);
            API.Web.AddNewsInfoCount({id:id});
        },
    }
}
</script>
<style lang="less" scoped>
.noticediv{
    max-width: 1200px;
    margin: auto;
    .noticecon{
        margin: 30px 20px 30px;
        border: 1px solid #ddd;
        min-height: 600px;
        background-color: #fff;
        .tit{
            color: #444;
            font-size: 20px;
            line-height: 30px;
            margin: 20px 20px 0;
            text-align: center;
            font-weight: bold;
        }
        .time{
            text-align: center;
            color: #666;
            font-size: 14px;
            margin-top: 10px;
            border-bottom: 1px solid #ddd;
            padding-bottom: 10px;
        }
        .txt{
            color: #555;
            font-size: 14px;
            line-height: 24px;
            text-indent: 28px;
            margin: 20px 30px 30px;
            overflow: hidden;
            img,video,p img,p video{
                width: auto;
                max-width: 100%;
                display: block;
                margin:auto;
            }
            div video{
                width: 100%;
            }
        }
    }
}
</style>